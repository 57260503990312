import { Component } from '@angular/core';
import { HeaderComponent } from '../../shared/header/header.component';
import { AgentService } from '../../services/agent.service';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

interface Agent {
    name: string;
    phone: string;
    retry_interval: number;
    max_retries: number;
}

@Component({
    selector: 'app-configuration',
    standalone: true,
    imports: [HeaderComponent, FormsModule, NgxSpinnerModule],
    templateUrl: './configuration.component.html',
    styleUrl: './configuration.component.scss',
})
export class ConfigurationComponent {
    maxAgents = 3;
    alertMessage =
        'There is a high priority ticket to attend to.  Can you please attend to it?';
    agents: any[] = [];
    loading: boolean = true;

    constructor(
        private agentsService: AgentService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit() {
        this.getAgents();
    }

    getAgents() {
        this.spinner.show();
        this.agentsService.getAgents().subscribe((data: any) => {
            const orderedKeys = [
                'first_engineer',
                'second_engineer',
                'third_engineer',
            ]; // Define the desired order
            this.agents = orderedKeys
                .map((key) => {
                    const agent = data[key] as Agent;
                    if (
                        !agent.retry_interval ||
                        !agent.max_retries ||
                        !agent.phone
                    ) {
                        return null;
                    }
                    return {
                        name: agent.name,
                        phone: agent.phone,
                        retry_interval: agent.retry_interval / 60, // Convert seconds to minutes
                        max_retries: agent.max_retries,
                    };
                })
                .filter((agent) => agent !== null);

            this.loading = false;
            this.spinner.hide();
        });
    }

    addAgent() {
        // Check if the number of agents is less than the maximum allowed
        if (this.agents.length >= this.maxAgents) {
            alert('You cannot add more than 3 agents.');
            return;
        }

        // Check if the last agent's fields are filled
        const lastAgent = this.agents[this.agents.length - 1];
        if (
            lastAgent.name !== '' &&
            lastAgent.phone !== '' &&
            lastAgent.retry_interval !== 0 &&
            lastAgent.max_retries !== 0
        ) {
            this.agents.push({
                name: '',
                phone: '',
                retry_interval: 0,
                max_retries: 0,
            });
        } else {
            alert(
                'Please fill in all fields for the current agent before adding another.'
            );
        }
    }

    saveAgents() {
        this.loading = true;
        if (this.allAgentsValid()) {
            const agentsToSave = this.convertAgentsToObject(this.agents);
            agentsToSave['minutes_interval'] = 5;
            this.agentsService
                .saveAgents(agentsToSave)
                .subscribe((data: any) => {
                    this.getAgents();
                    console.log(data);
                });
            console.log(agentsToSave); // This will now be in the desired format
        } else {
            alert('Please fill in all fields before saving.');
        }
    }

    // Method to convert agents array to the desired object structure
    convertAgentsToObject(agents: Agent[]) {
        const agentMapping = [
            'first_engineer',
            'second_engineer',
            'third_engineer',
        ];

        const result: any = {};
        agents.forEach((agent: Agent, index: number) => {
            if (agentMapping[index]) {
                result[agentMapping[index]] = {
                    name: agent.name,
                    phone: agent.phone,
                    retry_interval: agent.retry_interval * 60, // Convert minutes to seconds
                    max_retries: agent.max_retries,
                };
            }
        });

        return result;
    }

    // Method to check if all agents' fields are valid
    allAgentsValid() {
        return this.agents.every(
            (agent: any) =>
                agent.name !== '' &&
                agent.phone !== '' &&
                agent.retry_interval !== 0 &&
                agent.max_retries !== 0
        );
    }

    // Method to remove an agent
    removeAgent(index: number) {
        this.agents.splice(index, 1);
    }
}
