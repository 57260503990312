<div class="wrapper" [style.backgroundColor]="backgroundColor">
    <div class="header">
        <div class="logo" routerLink="/dashboard">
            <img [src]="imageUrl" alt="logo" />
        </div>
        <div class="menu">
            <a class="item" routerLink="/analytics" routerLinkActive="active"
                >Analytics</a
            >
            <a
                class="item"
                routerLink="/configuration"
                routerLinkActive="active"
                >Configuration</a
            >
            <!-- <a class="item" routerLink="/campaigns" routerLinkActive="active"
                >Campaigns</a
            >
            <a class="item" routerLink="/contacts" routerLinkActive="active"
                >Contacts</a
            >
            <a class="item" routerLink="/calls" routerLinkActive="active"
                >Calls</a
            >
            <a class="item" routerLink="/analytics" routerLinkActive="active"
                >Analytics</a
            > -->
        </div>
        <div class="user-area">
            <!-- <div class="search">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
          stroke="#4A4A48"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="notifications">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
      >
        <path
          d="M11.4417 20.5C11.2952 20.7525 11.0849 20.9622 10.8319 21.1079C10.5788 21.2536 10.292 21.3303 10 21.3303C9.70802 21.3303 9.42116 21.2536 9.16814 21.1079C8.91513 20.9622 8.70484 20.7525 8.55833 20.5M15 9.66663C15 8.34054 14.4732 7.06877 13.5355 6.13109C12.5979 5.19341 11.3261 4.66663 10 4.66663C8.67392 4.66663 7.40215 5.19341 6.46447 6.13109C5.52678 7.06877 5 8.34054 5 9.66663C5 15.5 2.5 17.1666 2.5 17.1666H17.5C17.5 17.1666 15 15.5 15 9.66663Z"
          stroke="#4A4A48"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="19" cy="4" r="3.5" fill="#E61C38" stroke="white" />
      </svg>
    </div> -->
            <div class="user">
                <img src="assets/images/user.png" alt="user-img" />
            </div>
        </div>
    </div>
</div>
