import { Routes } from '@angular/router';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CallsComponent } from './pages/calls/calls.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { LoginComponent } from './pages/login/login.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'analytics',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    // {
    //     path: 'dashboard',
    //     component: DashboardComponent,
    // },
    // {
    //     path: 'campaigns',
    //     component: CampaignsComponent,
    // },
    // {
    //     path: 'contacts',
    //     component: ContactsComponent,
    // },
    // {
    //     path: 'calls',
    //     component: CallsComponent,
    // },
    {
        path: 'analytics',
        component: AnalyticsComponent,
    },
    {
        path: 'configuration',
        component: ConfigurationComponent,
    },
];
