import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AgentService {
    constructor(private http: HttpClient) {}

    getAgents() {
        return this.http
            .get(`${environment.apiBaseUrl}/api/dashboard/get_agents`)
            .pipe(
                retry(3), // Retry the request up to 3 times if it fails
                catchError(this.handleError) // Handle errors
            );
    }

    saveAgents(agents: any) {
        return this.http
            .post(
                `${environment.apiBaseUrl}/api/dashboard/update_agents`,
                agents
            )
            .pipe(
                retry(3), // Retry the request up to 3 times if it fails
                catchError(this.handleError) // Handle errors
            );
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // Return an observable with a user-facing error message.
        return throwError('Something bad happened; please try again later.');
    }
}
